// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accounts-login-tsx": () => import("./../../../src/pages/accounts/login.tsx" /* webpackChunkName: "component---src-pages-accounts-login-tsx" */),
  "component---src-pages-accounts-reset-password-tsx": () => import("./../../../src/pages/accounts/reset-password.tsx" /* webpackChunkName: "component---src-pages-accounts-reset-password-tsx" */),
  "component---src-pages-analysis-bulk-export-tsx": () => import("./../../../src/pages/analysis/bulk-export.tsx" /* webpackChunkName: "component---src-pages-analysis-bulk-export-tsx" */),
  "component---src-pages-analysis-organization-tsx": () => import("./../../../src/pages/analysis/organization.tsx" /* webpackChunkName: "component---src-pages-analysis-organization-tsx" */),
  "component---src-pages-analysis-sites-tsx": () => import("./../../../src/pages/analysis/sites.tsx" /* webpackChunkName: "component---src-pages-analysis-sites-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-about-tsx": () => import("./../../../src/pages/info/about.tsx" /* webpackChunkName: "component---src-pages-info-about-tsx" */),
  "component---src-pages-info-privacy-policy-full-tsx": () => import("./../../../src/pages/info/privacy-policy-full.tsx" /* webpackChunkName: "component---src-pages-info-privacy-policy-full-tsx" */),
  "component---src-pages-info-privacy-policy-tsx": () => import("./../../../src/pages/info/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-info-privacy-policy-tsx" */),
  "component---src-pages-info-questions-tsx": () => import("./../../../src/pages/info/questions.tsx" /* webpackChunkName: "component---src-pages-info-questions-tsx" */),
  "component---src-pages-input-bulk-upload-tsx": () => import("./../../../src/pages/input/bulk-upload.tsx" /* webpackChunkName: "component---src-pages-input-bulk-upload-tsx" */),
  "component---src-pages-input-months-tsx": () => import("./../../../src/pages/input/months.tsx" /* webpackChunkName: "component---src-pages-input-months-tsx" */),
  "component---src-pages-input-scopes-tsx": () => import("./../../../src/pages/input/scopes.tsx" /* webpackChunkName: "component---src-pages-input-scopes-tsx" */),
  "component---src-pages-management-accounts-form-tsx": () => import("./../../../src/pages/management/accounts/form.tsx" /* webpackChunkName: "component---src-pages-management-accounts-form-tsx" */),
  "component---src-pages-management-accounts-index-tsx": () => import("./../../../src/pages/management/accounts/index.tsx" /* webpackChunkName: "component---src-pages-management-accounts-index-tsx" */),
  "component---src-pages-management-companies-index-tsx": () => import("./../../../src/pages/management/companies/index.tsx" /* webpackChunkName: "component---src-pages-management-companies-index-tsx" */),
  "component---src-pages-management-companies-tabs-general-tsx": () => import("./../../../src/pages/management/companies/tabs/general.tsx" /* webpackChunkName: "component---src-pages-management-companies-tabs-general-tsx" */),
  "component---src-pages-management-companies-tabs-idea-management-tsx": () => import("./../../../src/pages/management/companies/tabs/ideaManagement.tsx" /* webpackChunkName: "component---src-pages-management-companies-tabs-idea-management-tsx" */),
  "component---src-pages-management-companies-tabs-objectives-tsx": () => import("./../../../src/pages/management/companies/tabs/objectives.tsx" /* webpackChunkName: "component---src-pages-management-companies-tabs-objectives-tsx" */),
  "component---src-pages-management-companies-tabs-views-tsx": () => import("./../../../src/pages/management/companies/tabs/views.tsx" /* webpackChunkName: "component---src-pages-management-companies-tabs-views-tsx" */),
  "component---src-pages-management-plans-tsx": () => import("./../../../src/pages/management/plans.tsx" /* webpackChunkName: "component---src-pages-management-plans-tsx" */),
  "component---src-pages-private-tsx": () => import("./../../../src/pages/Private.tsx" /* webpackChunkName: "component---src-pages-private-tsx" */),
  "component---src-pages-reporting-anti-warming-tsx": () => import("./../../../src/pages/reporting/anti-warming.tsx" /* webpackChunkName: "component---src-pages-reporting-anti-warming-tsx" */),
  "component---src-pages-reporting-energy-saving-tsx": () => import("./../../../src/pages/reporting/energy-saving.tsx" /* webpackChunkName: "component---src-pages-reporting-energy-saving-tsx" */)
}

